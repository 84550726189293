import React, { useState } from 'react';

const IntroForm = ({ onComplete }) => {
  const [step, setStep] = useState(1);
  const [player1, setPlayer1] = useState('');
  const [player2, setPlayer2] = useState('');
  const [startingPlayer, setStartingPlayer] = useState('');

  const handleNextStep = () => {
    if (step === 1 && player1 && player2) {
      setStep(2);
    } else if (step === 2 && startingPlayer) {
      const gameData = {
        player1,
        player2,
        startingPlayer,
      };
      sessionStorage.setItem('gameData', JSON.stringify(gameData));
      onComplete(gameData);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen p-6">
      {step === 1 && (
        <div className="flex flex-col items-center gap-6">
          <h2 className="text-2xl font-bold">Inserisci i nomi dei giocatori</h2>
          <input
            type="text"
            placeholder="Nome Giocatore 1"
            value={player1}
            onChange={(e) => setPlayer1(e.target.value)}
            className="border rounded-lg px-4 py-2 w-64"
          />
          <input
            type="text"
            placeholder="Nome Giocatore 2"
            value={player2}
            onChange={(e) => setPlayer2(e.target.value)}
            className="border rounded-lg px-4 py-2 w-64"
          />
          <button
            onClick={handleNextStep}
            className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-600 transition"
          >
            Prossimo
          </button>
        </div>
      )}
      {step === 2 && (
        <div className="flex flex-col items-center gap-6">
            <h2 className="text-2xl font-bold text-gray-700">Chi inizia?</h2>
            <div className="flex flex-col gap-4">
              <button
                onClick={() => setStartingPlayer('player1')}
                className={`w-52 px-6 py-3 rounded-lg shadow text-lg font-bold ${
                  startingPlayer === 'player1'
                    ? 'accent-col text-white'
                    : 'bg-gray-400 text-gray-700 hover:bg-blue-100'
                }`}
              >
                {player1}
              </button>
              <button
                onClick={() => setStartingPlayer('player2')}
                className={`w-52 px-6 py-3 rounded-lg shadow text-lg font-bold ${
                  startingPlayer === 'player2'
                    ? 'accent-col text-white'
                    : 'bg-gray-400 text-gray-700 hover:bg-blue-100'
                }`}
              >
                {player2}
              </button>
            </div>
            <button
              onClick={handleNextStep}
              disabled={!startingPlayer}
              className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-600 transition disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Inizia
            </button>
        </div>
      )}
    </div>
  );
};

export default IntroForm;
