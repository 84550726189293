import React, { useState, useRef, useEffect, useMemo } from 'react';
import { gsap } from 'gsap';

const GenerateQuestion = ({ fallbackCards, onNewQuestion }) => {
  const [currentCard, setCurrentCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonColor, setButtonColor] = useState('#ef4444');
  const cardRef = useRef(null);
  const [turn, setTurn] = useState(null); 
  const [gameData, setGameData] = useState(null);

  const imagesToPreload = useMemo(() => [
    "/assets/images/questions.png",
    "/assets/images/challenges.png",
    "/assets/images/gaming.png",
    "/assets/images/discoveries.png",
    "/assets/images/intimacy.png",
  ], []);

  useEffect(() => {
    const storedGameData = JSON.parse(sessionStorage.getItem('gameData'));
    if (storedGameData) {
      setGameData(storedGameData);
      setTurn(storedGameData.startingPlayer);
    }
    preloadImages(imagesToPreload);
  }, [imagesToPreload]);

  const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  const getCategoryStyles = (category) => {
    const categoryStyles = {
      Domanda: {
        bg: 'bg-blue-200',
        text: 'text-blue-700',
        button: '#3b82f6',
        img: "/assets/images/questions.png  ",
      },
      Sfida: {
        bg: 'bg-red-200',
        text: 'text-red-700',
        button: '#ef4444',
        img: "/assets/images/challenges.png ",
      },
      Gioco: {
        bg: 'bg-green-200',
        text: 'text-green-700',
        button: '#22c55e',
        img: "/assets/images/gaming.png ",
      },
      Scoperta: {
        bg: 'bg-yellow-200',
        text: 'text-yellow-700',
        button: '#facc15',
        img: "/assets/images/discoveries.png  ",
      },
      Intimità: {
        bg: 'bg-purple-200',
        text: 'text-purple-700',
        button: '#9333ea',
        img: "/assets/images/intimacy.png ",
      },
    };
    return categoryStyles[category] || { bg: 'bg-gray-200', text: 'text-gray-700', button: '#6b7280' };
  };

  const handleGenerate = async () => {
    setLoading(true);
    

    // Animazione di caricamento
    if (cardRef.current) {
      if (window.innerWidth < 768) {
        gsap.fromTo(
          cardRef.current,
          { opacity: 0.5 },
          { opacity: 1, duration: 0.6, repeat: 3 } // Animazione leggera per mobile
        );
      } else {
        gsap.fromTo(
          cardRef.current,
          { opacity: 0.5, scale: 0.9 },
          { opacity: 1, scale: 1.1, duration: 0.6, repeat: -1, yoyo: true }
        );
      }
    }

    try {
      const randomFallback = fallbackCards[Math.floor(Math.random() * fallbackCards.length)];
      const promptText = randomFallback
        ? `Crea una domanda basata su questa carta: "${randomFallback.text}" appartenente alla categoria "${randomFallback.category}".`
        : 'Crea una domanda generica per un gioco di coppia.';

      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-4',
          messages: [
            {
              role: 'system',
              content: 'Sei un assistente che aiuta a creare domande per un gioco di carte per coppie.',
            },
            {
              role: 'user',
              content: promptText,
            },
          ],
          temperature: 0.9,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch from OpenAI');
      }

      const data = await response.json();
      const newQuestion = data.choices[0]?.message?.content.trim();

      gsap.killTweensOf(cardRef.current);
      setLoading(false);
      if (newQuestion) {
        const newCard = { text: newQuestion, category: randomFallback.category };
        setCurrentCard(newCard);
        setButtonColor(getCategoryStyles(newCard.category).button); // Aggiorna il colore del bottone
        onNewQuestion(newCard);
        toggleTurn();
      }
    } catch (error) {
      console.error('Error generating question:', error);

      // Usa fallback in caso di errore
      gsap.killTweensOf(cardRef.current);
      setLoading(false);
      const randomFallback = fallbackCards[Math.floor(Math.random() * fallbackCards.length)];
      setCurrentCard(randomFallback);
      setButtonColor(getCategoryStyles(randomFallback.category).button); // Aggiorna il colore del bottone
      onNewQuestion(randomFallback);
      toggleTurn();
    }
  };

  const toggleTurn = () => {
    if (gameData) {
      setTurn((prevTurn) =>
        prevTurn === gameData.startingPlayer ? 
          (gameData.startingPlayer === 'player1' ? 'player2' : 'player1') : 
          gameData.startingPlayer
      );
    }
  };
  

  return (
    
    <div className="flex flex-col items-center justify-center h-screen gap-20 z-10">
      <div
        ref={cardRef}
        className={`rounded-lg flex justify-center items-center pb-10 px-10 flex-col w-[350px] shadow-lg max-w-md text-center ${
          currentCard ? getCategoryStyles(currentCard.category).bg : 'bg-gray-200'
        }`}
        style={{ minHeight: '50vh' }}
      >
        {loading ? (
          <div
            className="spinner"
            style={{ borderTopColor: buttonColor }}
            role="status"
          >
            <span className="sr-only">Loading...</span>
          </div>
        ) : currentCard ? (
          <>

            <img src={`${getCategoryStyles(currentCard.category).img}`} alt={`${currentCard.category}`} style={{width: "100px"}}/>


            <h2 className={`text-3xl font-bold mb-2 ${getCategoryStyles(currentCard.category).text}`}>
              {currentCard.category}
            </h2>

            <p className="text-black text-2xl">{currentCard.text}</p>

            <p className="mt-4 text-lg font-semibold">
             È il turno di: <br/>
              <span className={`${getCategoryStyles(currentCard.category).text}`}>
                {turn === 'player1' ? gameData.player2 : gameData.player1}
              </span>
            </p>

          </>
        ) : (
          <p className="text-gray-500 text-xl">Premi il pulsante per pescare una carta!</p>
        )}
      </div>
      <button
        onClick={handleGenerate}
        style={{
          backgroundColor: buttonColor,
        }}
        className="mt-4 text-black font-bold py-3 px-8 rounded-3xl shadow-lg hover:opacity-80 transition-opacity"
      >
        Genera Nuova Domanda
      </button>
    </div>

  );
};

export default GenerateQuestion;
