import React, { useState, useEffect } from 'react';
import './global.css';
import 'tailwindcss/tailwind.css';
import GenerateQuestion from './components/GenerateQuestions';
import IntroForm from './components/IntroForm';

const DrawCardPage = () => {
  const [cards, setCards] = useState([]);
  const [backgroundClass, setBackgroundClass] = useState('bg-gray-200');
  const [introComplete, setIntroComplete] = useState(false); // Nuovo stato per il form

  useEffect(() => {
    fetch('/assets/data/cards.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const allCards = data.themes[0].categories.flatMap((category) =>
          category.cards.map((card) => ({ text: card, category: category.name }))
        );
        setCards(allCards);
      })
      .catch((error) => console.error('Failed to fetch cards:', error));
  }, []);

  const handleNewQuestion = (newCard) => {
    const categoryStyles = {
      Domanda: 'from-blue-500 via-blue-300 to-blue-200',
      Sfida: 'from-red-500 via-red-300 to-red-200',
      Gioco: 'from-green-500 via-green-300 to-green-200',
      Scoperta: 'from-yellow-500 via-yellow-300 to-yellow-200',
      Intimità: 'from-purple-500 via-purple-300 to-purple-200',
    };
    const newBgClass = categoryStyles[newCard.category] || 'from-gray-500 via-gray-300 to-gray-200';
    setBackgroundClass(`bg-gradient-to-b ${newBgClass}`);
  };

  // Genera gli span dinamicamente
  const renderSpans = (count = 20) => {
    // Array di colori da ciclare
    const colors = ['#FFACAC', '#E45A84', '#583C87'];
  
    return Array.from({ length: count }).map((_, index) => (
      <span
        key={index}
        style={{
          color: colors[index % colors.length],
        }}
      ></span>
    ));
  };

  // Gestione completamento introduzione
  const handleIntroComplete = (gameData) => {
    console.log('Game Data:', gameData); // Debug
    setIntroComplete(true);
  };

  return (
    <div className={`flex flex-col justify-between items-center h-screen ${backgroundClass}`}>
      {!introComplete ? (
        <IntroForm onComplete={handleIntroComplete} />
      ) : (
        <>
          <div className="background">{renderSpans(20)}</div>
          <GenerateQuestion fallbackCards={cards} onNewQuestion={handleNewQuestion} />
        </>
      )}
    </div>
  );
};

export default DrawCardPage;
